// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React from 'react';
import { useQuery } from 'react-fetching-library';
import qs from 'qs';

import { useStyles } from './studentLoginPage.styles';
import { Footer } from 'shared/templates/main/footer/Footer';
import { StudentLoginHeader } from './studentLoginHeader/studentLoginHeader';
import { Loader, Typography } from 'shared/components';
import { useLocale } from 'shared/hooks/useLocale/useLocale';
import { StudentLoginFormContainer } from './studentLoginForm/studentLoginFormContainer';
import { InstitutionDataResponse } from 'api/actions/login/login.types';
import { getInstitutionDataByEventId } from 'api/actions/login/login.actions';
import { Image } from 'shared/components/image/Image';

export const StudentLoginPage = () => {
  const styles = useStyles();
  const { formatMessage } = useLocale();
  const params: any = qs.parse(window.location.href.split('?')[1]);
  const { loading, payload } = useQuery<InstitutionDataResponse>(getInstitutionDataByEventId(params.id));

  return (
    <div>
      {loading ? (
        <Loader fullScreen withBackground />
      ) : (
        <div>
          <StudentLoginHeader />
          <div className={styles.container}>
            <div>
              {payload?.logoUrl && (
                <div className={styles.imgContainer}>
                  <Image className={styles.logoImage} alt="" src={payload?.logoUrl} />
                </div>
              )}
              <Typography className={styles.rootText}>
                {formatMessage({ id: 'av_user.student_Name_Listener.root_message' })}
              </Typography>
              <Typography className={styles.rootTextSub}>
                {formatMessage({ id: 'av_user.student_Name_Listener.root_message_sub' })}
              </Typography>
            </div>
            <div className={styles.card}>
              <Typography className={styles.header}>
                {formatMessage({ id: 'av_user.student_login.login_title' })}
              </Typography>
              <StudentLoginFormContainer />
            </div>
            <div className={styles.footerContainer}>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
