import { Action } from 'api/types';

export type CreateFetchInstitutionData = (eventId: string) => Action;
export type CreateFetchInstitutionEventData = (domain: string, partOfInstitutionId: string) => Action;
export type SendEmailForSubscripe = (participantId: string) => Action;
export type CreateFetchSubscriberData = (participantId: string) => Action;

export type CreateParticipantBody = {
  firstName: string;
  lastName: string;
  email: string;
  isAllowForMarketing: boolean;
};

export type SendEmailForSubscripeBody = {};

export type CreateFetchParticipantData = (eventId: string) => (body: CreateParticipantBody) => Action;

export enum ClipPurchaseTypes {
  None = 'None',
  WholesaleClientPays = 'WholesaleClientPays',
  WholesaleBDRClientPays = 'WholesaleBDRClientPays',
  RetailEndUserPays = 'RetailEndUserPays',
  PartnerNetworkHerffJones = 'PartnerNetworkHerffJones',
  PartnerNetworkLifeTouch = 'PartnerNetworkLifeTouch',
  PartnerNetworkReedGraduations = 'PartnerNetworkReedGraduations',
  UnknownAtThisTime = 'UnknownAtThisTime',
  PartnerNetworkFSM = 'PartnerNetworkFSM',
  PartnerNetworkTempest = 'PartnerNetworkTempest',
}

export type InstitutionDataResponse = {
  name: string;
  startDateOnly: Date;
  startTimeOnly: any;
  institutionName: string;
  institutionLogo: string;
  isAllowCollctiv: boolean;
  clipPurchaseType: ClipPurchaseTypes;
  isAllowForAmazonMusic: boolean;
};

type event = {
  eventId: string;
  name: string;
  clipPurchaseType: ClipPurchaseTypes;
};

export type InstitutionEventDataResponse = {
  institutionName: string;
  institutionLogo: string;
  isAllowCollctiv: boolean;
  isAllowForAmazonMusic: boolean;
  id: string;
  events: event[];
};

export type ConfirmCollectiveUiForParticipantBody = {
  allowDonation: string;
};

export type ConfirmParticipantCollectiveData = (
  participantId: string,
) => (body: ConfirmCollectiveUiForParticipantBody) => Action;

export type PostSubscriberData = (participantId: string) => (body: any) => Action;
