import React from 'react';
import { useMutation } from 'react-fetching-library';

import { CompleteCollective } from './ConfirmCollectiveUI';
import { ConfirmCollectiveUIContainerProps } from './ConfirmCollectiveUI.types';
import { confirmParticipantApproveCollectiveUI } from 'api/actions/selfRegistration/selfRegistration.actions';
import { ConfirmCollectiveUiForParticipantBody } from 'api/actions/selfRegistration/selfRegistration.types';

export const ConfirmCollectiveUIContainer = ({
  activeStep,
  participantId,
  setActiveStep,
  instituteLogo,
  isRetail,
  isAllowForAmazonMusic,
  isAllowForAmazonPrime,
  ...props
}: ConfirmCollectiveUIContainerProps) => {
  const { mutate, loading } = useMutation(confirmParticipantApproveCollectiveUI(participantId));

  const onSubmit = async (body: ConfirmCollectiveUiForParticipantBody) => {
    await mutate(body);
    setActiveStep(activeStep + 1);
  };

  const handleNextAmazon = () => {
    const amazonMusicSignUpURL = `https://www.tryamazonmusic.com/c/4060651/2156199/15290?SubId1=${participantId}`;
    const amazonPrimeSignUpURL = `https://primevideo-eu.pxf.io/c/4060651/1978462/20021?SubId1=${participantId}`;
    window.open(isAllowForAmazonPrime ? amazonPrimeSignUpURL : amazonMusicSignUpURL, '_blank');

    setActiveStep(activeStep + 1);
  };
  const handleNextAmazonCancel = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <CompleteCollective
      isLoading={loading}
      activeStep={activeStep}
      handleButtonClick={onSubmit}
      instituteLogo={instituteLogo}
      isRetail={isRetail}
      isAllowForAmazonMusic={isAllowForAmazonMusic}
      isAllowForAmazonPrime={isAllowForAmazonPrime}
      handleButtonAmazonMusicClick={handleNextAmazon}
      handleNextAmazonCancel={handleNextAmazonCancel}
      {...props}
    />
  );
};
